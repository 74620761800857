* {
    box-sizing: border-box;

    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    // background: #9075A5;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.2rem 0 0 0.23rem;
    justify-content: space-between;
    z-index: 999;
    @media (max-width: 900px) {
        padding: 0.45rem 0.3rem;
    }
    .text {
        font-family: "Roboto", serif;
        font-weight: 500;
        font-size: 0.24rem;
        color: rgba(#000000, 0.35);
        line-height: 0.35rem;
    }
    .logo_box {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
            display: none;
        }
        .logo {
            width: 0.52rem;
            height: 0.52rem;
            border-radius: 0.08rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.08rem;
            }
        }
        .text {
            margin-left: 0.14rem;
            color: rgba(#000000, 0.78);
        }
    }
    .link_box {
        margin-left: 0.58rem;
        @media (max-width: 900px) {
            margin-left: 0;
            display: flex;
            width: 100%;
        }
        .text {
            padding: 0 0.47rem;
            @media (max-width: 900px) {
                display: block;
                width: 35%;
                font-size: 0.2rem;
                line-height: 0.3rem;
                padding: 0;
                text-align: center;
            }
            &:hover {
                color: #4da65c !important;
                color: rgba(#000000, 1);
            }
        }

        .ml {
            border-left: 0.03rem solid rgba(0, 0, 0, 0.1);
            border-right: 0.03rem solid rgba(0, 0, 0, 0.1);
            @media (max-width: 900px) {
                border: none;
            }
        }
    }
}

.page {
    width: 100%;
    height: 100%;
    background: #f9faf3;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        flex-direction: column;
        padding-left: 0.34rem;
        padding-top: 2.3rem;
        height: auto;
        padding-bottom: 0.9rem;
        min-height: 100%;
    }
    .left {
        width: 65%;
        padding: 0 1.09rem;
        @media (max-width: 900px) {
            width: 100%;
            padding: 0 0.7rem 0 0;
        }
        .title {
            font-family: "Roboto", serif;
            font-weight: 600;
            font-size: 0.68rem;
            line-height: 0.78rem;
            @media (max-width: 900px) {
                font-size: 0.56rem;
                line-height: 0.64rem;
            }
            span {
                font-family: "Libre Baskerville", serif;
                color: #4da65c;
            }
        }
        .tip {
            width: 90%;
            font-family: "Roboto", serif;
            font-weight: 400;
            font-size: 0.24rem;
            color: rgba(0, 0, 0, 0.5);
            line-height: 0.36rem;
            margin-top: 0.47rem;
            @media (max-width: 900px) {
                width: 95%;
                margin-top: 0.53rem;
            }
        }
        .app_store {
            width: 3.84rem;
            height: 1.16rem;
            margin-top: 0.55rem;
        }
    }
    .right {
        width: 38%;
        padding-right: 0.56rem;
        @media (max-width: 900px) {
            width: 100%;
            padding-right: 0;
            margin-top: 0.94rem;
        }
        img {
            width: 100%;
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: #f9faf3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
        background: #f9faf3;
    }
    .pact-box {
        width: 75%;
        height: 80%;
        @media (max-width: 900px) {
            height: 88%;
        }
        .title {
            padding-top: 0.3rem;
            font-family: "Libre Baskerville", serif;
            font-weight: 400;
            font-size: 0.68rem;
            color: #4da65c;
            line-height: 0.78rem;
            padding-bottom: 0.49rem;

            @media (max-width: 900px) {
                padding-bottom: 0.33rem;
                font-size: 0.54rem;
                line-height: 0.64rem;
            }
        }
        .pact-cont {
            height: 80%;
            overflow: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @media (max-width: 900px) {
                height: 85%;
            }
            .text_box {
                display: flex;
                margin-top: 0.26rem;
                @media (max-width: 900px) {
                    flex-direction: column;
                }
                h2 {
                    width: 38%;
                    padding-right: 0.3rem;
                    font-family: "Heebo", serif;
                    font-weight: bold;
                    font-size: 0.28rem;
                    color: #000;
                    line-height: 0.32rem;
                    margin-bottom: 0.3rem;
                    @media (max-width: 900px) {
                        font-size: 0.24rem;
                        line-height: 0.28rem;
                        width: 100%;
                    }
                }
                .text {
                    flex: 1;
                    p,
                    li {
                        font-family: "Roboto", serif;
                        font-weight: normal;
                        font-size: 0.22rem;
                        color: #000;
                        line-height: 0.32rem;
                        padding-bottom: 0.2rem;
                        @media (max-width: 900px) {
                            font-size: 0.24rem;
                            line-height: 0.28rem;
                        }
                    }
                    strong {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
